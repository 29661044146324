<template>
  <v-menu offset-y offset-x content-class="home--popup-container">
    <template v-slot:activator="{ on, attrs }">
      <div class="type--dropdown-item" v-bind="attrs" v-on="on">
        <div>{{ title }}</div>
        <i class="ion-ios-arrow-down ml-2"></i>
      </div>
    </template>
    <div class="all--property-type">
      <router-link :to="`/${listingType === 'R' ? 'sewa' : 'beli'}/search/semua`">
        {{ `${$t('home.seeAll')} ${title} >` }}
      </router-link>
    </div>
    <div class="d-flex">
      <div class="property--types-wrapper">
        <router-link
          :to="`/${listingType === 'R' ? 'sewa' : 'beli'}/search${content.url}`"
          class="property--type-item"
          v-for="(content, index) in propertyTypes"
          :key="`banner-property-type-${index}`"
        >
          <div class="img--wrapper">
            <img :src="content.img" :alt="content.name" />
          </div>
          <div>{{ content.name }}</div>
        </router-link>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'header-user',
  props: ['listingType', 'propertyTypes', 'title'],
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>
